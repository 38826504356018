<template>
  <div
    id="loading-bg"
    class="position-absolute position-left-0 position-right-0 position-bottom-0 position-top-0"
  >
    <div class="loading-logo">
      <img
        src="/logo.svg"
        :alt="$config.brand.name"
      >
    </div>
    <div class="loading">
      <div class="effect-1 effects" />
      <div class="effect-2 effects" />
      <div class="effect-3 effects" />
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */

import { NAME_FARMERS } from '@/router/routes/farmers'
import { NAME_NOW } from '@/router/routes/now'

export default {
  components: {},
  data() {
    return {}
  },
  computed: {},
  async mounted() {
    if (this.$store.getters['auth/getUser'] === null) {
      await this.$store.dispatch('auth/fetchUserProfile')
    }

    if (!this.$store.getters['farmers/isFetch']) {
      await this.$store.dispatch('farmers/fetch')
    }

    if (!this.$store.getters['farmers/isLoad']) {
      if (!(await this.$store.dispatch('farmers/loadFarmer'))) {
        await this.$router.replace({ name: NAME_FARMERS })
        return
      }
    }

    window.isReady = true
    await this.$router.replace({ name: NAME_NOW })
  },
}
</script>

<style lang="scss" scoped>
@import '../@core/scss/vue/pages/page-misc.scss';
</style>
